.footer {
    background: #fff;
    padding: 2rem 20px 1rem
  }
  
  .footer__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .footer__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    opacity: .5;
  }
  
  .footer__bottom .relations {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .footer__rights {
    font-size: .75rem;
    margin-bottom: .25rem;
  }
  
  .footer__rights a {
    color: #2A59FF;
    font-weight: 500;
    transition: color .3s ease-out;
    will-change: color;
  }
  
  .footer__rights a:hover,
  .footer__rights a:active,
  .footer__rights a:focus {
    color: #2994FF
  }
  
  .footer__app {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .footer__app > * {
    cursor: pointer;
  }
  .menu-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .85rem;
  }
  
  .menu-2__item:not(:last-of-type) {
    margin-right: 30px;
  }
  
  .menu-2 a {
    color: #757575;
    -webkit-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
    will-change: color;
  }
  
  .dark .menu-2 a {
    color: #bdbdbd;
  }
  
  .menu-2 a:hover {
    color: #2A59FF;
  }

  .emails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .emails .email-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .emails .email-item:not(:last-child) {
    margin-bottom: .5rem;
  }

  .emails .email-item i {
    font-size: .85rem;
    margin-right: .25rem;
  }
  
  .footer__logo a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .footer__logo img {
    max-width: 200px;
  }
  
  @media (max-width: 956px) {
    .footer__top {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .footer__logo {
      margin-bottom: 20px;
    }
  
    .footer__menu-2 {
      margin-bottom: 20px !important;
    }
  
    .footer__social {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .menu-2__item:not(:last-of-type) {
      margin-right: 15px;
    }
  }
  
  @media (max-width: 767.68px) {
    .footer__bottom {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  
    .footer__bottom .relations {
      margin-bottom: 1rem;
      order: 2;
      justify-content: center;
      align-items: center;
    }
  }
  
  @media (max-width: 550px) {
    .footer__top {
      margin-bottom: 25px;
    }
  
    .menu-2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .menu-2__item:not(:last-of-type) {
      margin: 0 0 10px 0;
    }
  }
  .social-icons {
    margin-bottom: 1rem;
  }
  
  .social-icons a {
    padding: .5rem;
    color: #8a8a8a;
    background: #f5f5f5;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: background .3s ease-out, color .3s ease-out;
    will-change: background, color;
  }
  
  .social-icons.big a {
    height: 3rem;
    width: 3rem;
  }
  
  .social-icons a:not(:last-child) {
    margin-right: .5rem;
  }
  
  .social-icons a i {
    color: #8a8a8a;
  }
  
  .social-icons.big a i {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .social-icons a:hover,
  .social-icons a:focus,
  .social-icons a:active {
    color: #fff;
  }
  
  .social-icons a:hover i,
  .social-icons a:focus i,
  .social-icons a:active i {
    color: #fff;
  }
  
  .social-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .si-facebook:hover,
.si-colored.si-facebook {
  background: #3b5998 !important;
}

.si-google:hover,
.si-colored.si-google {
  background: #DB4437 !important;
}

.si-vk:hover,
.si-colored.si-vk {
  background: #4c75a3 !important;
}

.si-rss:hover,
.si-colored.si-rss {
  background: #EE802F !important;
}

.si-twitter:hover,
.si-colored.si-twitter {
  background: #00ACEE !important;
}

.si-youtube:hover,
.si-colored.si-youtube {
  background: #FF0000 !important;
}

.si-instagram:hover,
.si-colored.si-instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
}

.si-telegram:hover,
.si-colored.si-telegram {
  background: #0088cc !important;
}
.social__link {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.85rem;
    color: #2A59FF;
    transition: color .3s ease-out;
    will-change: color;
  }
  
  .social__link:hover,
  .social__link:active,
  .social__link:focus {
    color: #2994FF;
  }
  
  .social__link i {
    margin-right: .25rem;
  }